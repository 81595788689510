import React, { ReactElement } from 'react';

export function DynamicExternal(): ReactElement {
  return (
    <section className="full-bleed isolate min-h-[209px] bg-white pb-4 dark:bg-black">
      <div
        className="yotpo yotpo-pictures-widget"
        data-gallery-id="5ff4f42842c01f000fd13909"
      />
    </section>
  );
}
